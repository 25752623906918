/*
 * IMPORTANT: This file ("the Theinhardt @font-face file") is how the CMS loads
 * the fonts below, which are under license. Our license with the vendor says
 * that we must include the disclaimer below. To prevent it from being stripped
 * out of the production CSS bundle by the Gatsby minifier (cssnano), we add
 * a special cssnano pattern `/*!` which says "Don't remove this comment".
 *
 * Further reading: https://cssnano.co/optimisations/discardcomments/
 *
 * See also: gatsby-ssr.js
 *
 * Please do not remove the disclaimer or the `/*!`
 */

/*!

/*
 * Legal Disclaimer
 *
 * These Fonts are licensed only for use on these domains and their subdomains:
 * "ethoslife.com"
 *
 * It is illegal to download or use them on other websites.
 *
 * While the @font-face statements below may be modified by the client, this
 * disclaimer may not be removed.
 *
 * optimo.ch, 2019
 */

/* Fonts
  ----------------------------------------------------------------------------- */

@font-face {
  font-family: 'Theinhardt';
  font-weight: 400;
  font-display: fallback;
  unicode-range: U+000-5FF;
  src: url('./Theinhardt-Regular.woff2') format('woff2'),
    url('./Theinhardt-Regular.woff') format('woff');
}

@font-face {
  font-family: 'Theinhardt';
  font-weight: 500;
  font-display: fallback;
  unicode-range: U+000-5FF;
  src: url('./Theinhardt-Medium.woff2') format('woff2'),
    url('./Theinhardt-Medium.woff') format('woff');
}

@font-face {
  font-family: 'Theinhardt';
  font-weight: 300;
  font-display: fallback;
  unicode-range: U+000-5FF;
  src: url('./Theinhardt-Light.woff2') format('woff2'),
    url('./Theinhardt-Light.woff') format('woff');
}
