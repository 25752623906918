@font-face {
  font-family: 'Cambon';
  font-weight: 400;
  font-display: swap;
  unicode-range: U+000-5FF;
  src: url('./Cambon-Regular.woff2') format('woff2'),
    url('./Cambon-Regular.woff') format('woff');
}

@font-face {
  font-family: 'Cambon';
  font-weight: 500;
  font-display: swap;
  unicode-range: U+000-5FF;
  src: url('./Cambon-Book.woff2') format('woff2'),
    url('./Cambon-Book.woff') format('woff');
}

@font-face {
  font-family: 'Cambon';
  font-weight: 600;
  font-display: swap;
  unicode-range: U+000-5FF;
  src: url('./Cambon-Demi.woff2') format('woff2'),
    url('./Cambon-Demi.woff') format('woff');
}

@font-face {
  font-family: 'Cambon';
  font-weight: 700;
  font-display: swap;
  unicode-range: U+000-5FF;
  src: url('./Cambon-Bold.woff2') format('woff2'),
    url('./Cambon-Bold.woff') format('woff');
}
