@import 'ethos-design-system/src/components/Animation.scss';
@import 'ethos-design-system/src/components/Colors.scss';
@import 'ethos-design-system/src/components/design-system.css';
@import 'ethos-design-system/src/components/Normalize.scss';
@import 'ethos-design-system/src/components/Variables.scss';
@import 'ethos-design-system/src/components/Button/Button.module.scss';
@import 'ethos-design-system/src/components/CheckboxInput/CheckboxInput.module.scss';
@import 'ethos-design-system/src/components/FlexGrid/flexboxgrid2-customized.css';
@import 'ethos-design-system/src/components/Images/Images.module.scss';
@import 'ethos-design-system/src/components/Layout/Layout.scss';
@import 'ethos-design-system/src/components/Spacer/Spacer.scss';
@import 'ethos-design-system/src/components/TypeBase/TypeBase.module.scss';
@import 'ethos-design-system/src/components/TypeBase/TypeBase.scss';
@import 'ethos-design-system/src/components/UniversalNavbarExpanded/Attributes.css';

/** ---------------------------- */
/** Define Global Variables      */
/** ---------------------------- */
body {
  --Navbar-Height: var(--Space-64);
  --banner-offset-height: 0;
}
