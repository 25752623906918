// This file and global CSS generally are deprecated. Please use *.module.scss.

/** --------------------------------------------------------  */
/** Main CMS Sass File                                        */

/** --------------------------------------------------------  */
@import '~ethos-design-system/src/components/Media/Media.scss';

/** ---------------------------- */
/** Define Global Styles      */
/** ---------------------------- */

.CmsLayoutRoot {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}

.CmsLayoutRoot > main {
  flex: 1 1 0;
}

figure {
  margin: 0;
}

.CmsLayout {
  /* Prevent Chrome, Opera, and Safari from letting these items shrink to smaller than their content's default minimum size. */
  flex: 1 0 auto;
}
.DynamicComponent--SocialProofBanner {
  background: #e7f2ee;
  margin: 0;
}

.DynamicComponent--StarTestimonial {
  background: var(--White);
  margin: 0;
}

.DynamicComponent--NavigableContent p > a:not(.Link),
.DynamicComponent--BlogStory .BlogStory a {
  color: var(--BrandForest);
  &,
  &:hover {
    text-decoration: underline;
  }
  &:active,
  &:hover {
    color: var(--GraySecondary--translucent);
  }
  &:active {
    text-decoration: none;
  }
}

// .BasicClickthrough {
//   // Maybe this will be a DS link/<a> element?
//   text-decoration: none;
//   color: var(--BrandSand);
//   &:hover {
//     color: var(--BrandForest);
//   }
//   &:active {
//     color: var(--BrandForest);
//   }
// }

// These styles add automatic padding to most modules that don't have it inline.
// Let's consider this approach experimental. There are pros and cons to both
// (spacing in module vs. globally like this).

@mixin default-content-container {
  max-width: var(--ContainerMaxWidth);
  margin: 0 auto;
}

@mixin default-horizontal-padding {
  @include for-phone-only {
    padding: 0 var(--ContainerPadding--Phone);
  }
  @include for-tablet-only {
    padding: 0 var(--ContainerPadding--Tablet);
  }
  @include for-laptop-only {
    padding: 0 var(--ContainerPadding--Laptop);
  }
  @include for-desktop-only {
    padding: 0 var(--ContainerPadding--Desktop);
  }
}

@mixin default-vertical-padding {
  & [class*='__Wrapper'] {
    padding: 24px 0;
    @include for-tablet-and-up {
      padding: 40px 0;
    }
    @include for-laptop-and-up {
      padding: 72px 0;
    }
  }
}

// @todo A lot of these have been commented out due to bespoke spacing
// per module during Rebrand. We can probably still consolidate back to a
// a global system of sorts. See two mixins above.

// .DynamicComponent--HeaderVersatile,
// .DynamicComponent--HeaderSimple,
// .DynamicComponent--HeaderSemiversatile,
// .DynamicComponent--Checkerboard,
// .DynamicComponent--Listicle,
// .DynamicComponent--BannerLogos,
// .DynamicComponent--BannerText,
// .DynamicComponent--BannerImage,
// .DynamicComponent--ComparisonChartProsCons,
// .DynamicComponent--ComparisonChartYesNo,
// .DynamicComponent--Highlight,
.DynamicComponent--FaqCategory,
// .DynamicComponent--NavigableContent,
// .DynamicComponent--Highlight,
.DynamicComponent--Testimonials {
  @include default-vertical-padding;
}

// .DynamicComponent--HeaderVersatile,
// .DynamicComponent--HeaderSimple,
// .DynamicComponent--HeaderSemiversatile,
.DynamicComponent--Checkerboard,
// .DynamicComponent--Listicle,
// .DynamicComponent--BannerLogos,
// .DynamicComponent--BannerText,
.DynamicComponent--BannerImage,
.DynamicComponent--ComparisonChartProsCons,
.DynamicComponent--ComparisonChartYesNo,
// .DynamicComponent--Highlight,
.DynamicComponent--FaqCategory,
.DynamicComponent--NavigableContent,
.DynamicComponent--BlogImage,
.DynamicComponent--BlogImageSplit,
.DynamicComponent--BlogPreview,
.DynamicComponent--BlogStory,
.DynamicComponent--Testimonials {
  @include default-horizontal-padding;
}

.DynamicComponent--BannerImage,
// .DynamicComponent--BannerLogos,
.DynamicComponent--BannerText,
.DynamicComponent--BlogImage,
.DynamicComponent--BlogImageSplit,
.DynamicComponent--BlogPreview,
.DynamicComponent--BlogStory,
// .DynamicComponent--Checkerboard,
.DynamicComponent--ComparisonChartProsCons,
.DynamicComponent--ComparisonChartYesNo,
.DynamicComponent--FaqCategory,
.DynamicComponent--Greenhouse,
.DynamicComponent--HeaderSemiversatile,
.DynamicComponent--HeaderSimple,
// .DynamicComponent--HeaderVersatile,
// .DynamicComponent--Listicle,
.DynamicComponent--NavigableContent,
// .DynamicComponent--Highlight,
.DynamicComponent--Testimonials {
  @include default-content-container;
}
.DynamicComponent--BannerLogos {
  background: var(--GrayLightHover--opaque);
  margin: 0;
}

// This is a WIP class generation, still to be applied to *-template.js files.
// Currently it's only output on collections using the general-template.js file.
// Blog Single Template undergoes a rewrite in gatsby-node.js to make use of this.
// This was introduced to target the blog single entry FE view with a wrapping class
// and add some bottom padding.
.PageTemplate--blog-single-template {
  padding: 0 0 24px 0;
  @include for-tablet-and-up {
    padding: 0 0 40px 0;
  }
  @include for-laptop-and-up {
    padding: 0 0 72px 0;
  }
}

.PageTemplate--demo-template {
  display: flex;

  .demoModules {
    width: 100%;
    @include for-tablet-and-up {
      padding-right: 260px;
    }
  }
}

.AnimatedNavbar {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
}

.Label2 {
  font-size: 12px;
  line-height: 15px;
  letter-spacing: 0.0075em;
}

.slick-track {
  display: flex !important;
}

.slick-slide {
  height: inherit !important;
  > div {
    height: 100%;
  }
}
.slick-dots {
  .slick-active {
    button::before {
      color: #056257 !important;
    }
  }
}

div[role='dialog'] {
  overflow: visible;
}
